import React from 'react'

function StatusRow({ trackStatus }) {
    var d = new Date(trackStatus.date);
    // var formattedTime = d.toLocaleDateString("pt-br") + " " + d.toLocaleTimeString("pt-br")
    var formattedTime = d.toLocaleString("pt-br")

    // console.log(trackStatus)

    const switchIconStatus = (statusIcon) => {
        let obj =''
        switch (statusIcon) {
            case 'PO': case 'LDE':
                obj = <span className="flaticon-box"></span>
                break;

            case 'RO': case 'DO': case 'LDI' :case 'PAR': 
                obj = <span className="flaticon-delivery-truck"></span>
                break;

            case 'BLQ': case 'OEC':
                obj = <span className="flaticon-trolley-cart"></span>
                break;
            case 'BDI': case 'BDR': case 'BDE':
                obj = <span className="flaticon-received"></span>
                break;

            default:
                break;
        }
        return obj
    }

    return (
        <div className="row status">
            <div className="column left-col">
                {/* <span className="flaticon-box"></span><br /> */}
                {switchIconStatus(trackStatus.status)}<br />
                <span><b>{trackStatus.descricao}</b></span> <br />
                {/* <span>29/10/2020 18:04:00</span> */}
                <span>{formattedTime}</span>
            </div>
            <div className="column right-col">
                {/* <p>CTC JAGUARE/GCCAP4 - São Paulo/SP Sujeito a encaminhamento no próximo dia útil.</p> */}
    <p>{trackStatus.local}/{trackStatus.cep} - {trackStatus.city}/{trackStatus.state} {trackStatus.message || ''}</p>
            </div>
        </div>
    )
}

export default StatusRow