import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import './lista.css';

import SingleOrder from "../AreaOrder/AreaOrder"


const Lista = () => {

    const sessionSt = JSON.parse(sessionStorage.getItem('data'))
    // const [state, setState] = useState(sessionSt ? sessionSt : {});

    if (!sessionSt) {
        return <Redirect to='/' />
    }

    return (
        <div className="list-order">
            <div className="links-redirect">
                <span> <Link to="/">Inicio</Link> / <Link to="/lista">Lista</Link></span>
            </div>

            <h1>Olá {sessionSt.response.name}</h1>
            
            {sessionSt.response.sales.map((resp, index) => {
                return <SingleOrder dataSales={resp} key={index} ></SingleOrder>
            })}
        </div>
    )
}

export default Lista