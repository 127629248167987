import logo from "../assets/images/logo-b4you-log.png";
import Routes from "../routes"
import './App.css';



function App() {
    
    // const logo = "https://business.b4you.com.br/assets/img/logo2.png"
    return (
        <section className="area-form">
            <div className="header-area">
                <img src={logo} alt="" />
            </div>
            <Routes/>
            <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <p className="copyright">© 2019 - {new Date().getFullYear()} B4YOU - Todos os Direitos Reservados.</p>
        </section>
    );
}

export default App;
