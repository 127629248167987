import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import './rastreio.css'

import api from '../../api'
import StatusRow from "../StatusRow/StatusRow"
import StatusTimeline from '../StatusTimeline/StatusTimeline'

const Rastreio = ({ match }) => {

    const urlCdTrack = match.params.urlCdTrack
    let cdTrack = JSON.parse(sessionStorage.getItem('cdTrackCurrent'))
    const [trackingT, setTrackingT] = useState(null);
    const [testTrack, setTestTrack] = useState(null)
    const [isloading, setLoading] = useState(true)

    useEffect(() => {
        if (urlCdTrack) {
            async function getTrackUrl() {
                const responseUrl = await api.get('tracking/?tracking=' + urlCdTrack + '&token=ASU588S123ADHI986JKAIA123SDGH')
                setTestTrack(responseUrl.data)
                setTrackingT(responseUrl.data)
                setLoading(false)
            }
            getTrackUrl()

        } else {
            setLoading(false)
            setTrackingT(JSON.parse(sessionStorage.getItem('trackCurrent')))
        }
    }, [urlCdTrack, cdTrack]);

    if (!cdTrack && !urlCdTrack) {
        return <Redirect to='/lista ' />
    }

    if (testTrack && testTrack.error) {
        return (
            <div className="error-track">
                <h1>Código não encontrado </h1>
                <p>O seguinte código <b><span>{urlCdTrack}</span></b> não foi encontrado.</p>
                <p>Pedimos desculpa, mas o código pode estar errado ou não existe!</p>
                <p>Verifique na barra de endereço do seu browser de internet se ele está correto.</p>
                <p>Se estiver tudo OK, por que não tenta fazer uma pesquisa na pagina principal pelo CPF ou Email <Link to="/">Home</Link></p>
            </div>
        )
    }

    if (isloading) {
        return <div className="spinner-load-track"></div>
    } 
    else if(!trackingT.response.status){
        return (
            <div className="error-track">
                <h1>Código Encontrado mas sem atualização nos Correios</h1>
                <p>O seguinte código <b><span>{urlCdTrack}</span></b> ainda está em processo.</p>
                <p>O seu pedido já foi enviado, mas ainda não ocorreu nenhum progresso, volte mais tarde para novas atualizações.</p>
            </div>
        )
    }
    else if(trackingT.response.status){
        return (
            <div className="content-area-track">
                {(cdTrack && !urlCdTrack) &&
                    <div className="links-redirect">
                        <span> <Link to="/">Inicio</Link> / <Link to="/lista">Lista</Link> / <Link to="/rastreio">Rastreio</Link>  </span>
                    </div>
                }
                
                {(cdTrack && !urlCdTrack) && <h1>Código do Rastreio: <span className="cd-track">{cdTrack}</span></h1>}
                {urlCdTrack && <h1>Código do Rastreio: <span className="cd-track">{urlCdTrack}</span></h1>}
                <h1>Informação do Rastreio</h1>

                {trackingT &&
                    <StatusTimeline lastStatus={trackingT.response.status.events[0]} status={trackingT.response.status.events}></StatusTimeline>
                }

                <h5>Enviado pelos: Correios/{trackingT && trackingT.response.status.type}</h5>

                <div className="row title-status">

                    <div className="column left-col">
                        <h1>Status</h1>
                    </div>
                    <div className="column right-col">
                        <h1>Movimentação</h1>
                    </div>
                    <div className="column">
                        <h1>Status/Movimentação</h1>
                    </div>
                </div>

                {trackingT &&
                    trackingT.response.status.events.map((resp, index) => {
                        return <StatusRow trackStatus={resp} key={index}></StatusRow>
                    })
                }
            </div>
        )
    }
}

export default Rastreio