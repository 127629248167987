import React from 'react'
// import OnlyTimeline from '../OnlyTimeline/OnlyTimeline'

export default function StatusTimeline({ lastStatus, status }) {

    const statusPost = (status[status.length - 1])
    const statusEncam = findElement(status, 'status', /RO|DO/gi)
    const statusSaiu = findElement(status, 'status', /OEC/gi)
    const statusEntrega = findElement(status, 'status', /BDE|BDI|BDR/gi)

    function findElement(arr, propName, propValue) {
        if (!arr) {
            return ''
        } else {
            return arr.filter(event => (propValue).test(event.status))[0];
        }
    }

    const formattedTime = (time, tipeTime) => {

        var d = new Date(time)

        if (tipeTime === 'Full') {
            return d.toLocaleString("pt-br")
        } else if (tipeTime === 'Date') {
            return d.toLocaleDateString('pt-br')
        } else if (tipeTime === 'Hour') {
            return d.toLocaleTimeString('pt-br')
        }

    }
    function isCompleted(id) {
        if (id === 1 && ((/PO|RO|DO|OEC|LDI|LDE|BDE|BDI|BDR/gi).test(lastStatus.status))) return 'completed'
        if (id === 2 && (lastStatus.status === 'RO' || lastStatus.status === 'DO' || lastStatus.status === "BDE" || lastStatus.status === "OEC")) return 'completed'
        if (id === 3 && (lastStatus.status === 'LDI' || lastStatus.status === 'BDR' || lastStatus.status === 'BLQ' || lastStatus.status === 'OEC' || lastStatus.status === "BDE")) return 'completed'
        if (id === 4 && (lastStatus.status === 'BDE' || lastStatus.status === 'BDI' || lastStatus.status === 'BDR')) return 'completed'
    }



    return (
        <div className="hh-grayBox">
            <div className={`order-tracking ${isCompleted(1)}`}>
                <span className="is-complete flaticon-box"></span>
                <p><b>Postado</b><br /><span>{formattedTime(statusPost.date, 'Date')}</span><br /><span>{formattedTime(statusPost.date, 'Hour')}</span></p>
            </div>
            <div className={`order-tracking ${isCompleted(2)}`}>
                <span className="is-complete flaticon-delivery-truck"></span>
                <p><b>Encaminhado</b><br /><span>{statusEncam ? formattedTime(statusEncam.date, 'Date') : ' '}</span><br /><span>{formattedTime(statusEncam.date, 'Hour')}</span></p>
            </div>

            <div className={`order-tracking ${isCompleted(3)}`}>
                <span className="is-complete flaticon-trolley-cart"></span>
                <p><b>Saiu para a Entrega</b><br /><span>{statusSaiu ? formattedTime(statusSaiu.date, 'Date') : ' '}</span><br /><span>{statusSaiu ? formattedTime(statusSaiu.date, 'Hour') : ''}</span></p>
            </div>

            <div className={`order-tracking ${isCompleted(4)}`}>
                <span className="is-complete flaticon-received"></span>
                <p><b>Entregue</b><br /><span>{statusEntrega ? formattedTime(statusEntrega.date, 'Date') : ' '}</span><br /><span>{statusEntrega ? formattedTime(statusEntrega.date, 'Hour') : ' '}</span></p>
            </div>
        </div>

    )
}