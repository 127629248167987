import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
// import { Link } from 'react-router-dom'

import api from '../../api'


function AreaOrder({ dataSales }) {

    const [isLoading, setLoading] = useState(false);
    var d = new Date(dataSales.dateFinishedSale)
    var formattedTime = d.toLocaleString("pt-br")
    // var formattedTime = d.toLocaleDateString("pt-br") + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds()
    let history = useHistory();


    async function onRequestApi(cdTrack) {
        setLoading(true)
        sessionStorage.setItem('cdTrackCurrent', JSON.stringify(cdTrack))

        await api.get('tracking/?tracking=' + cdTrack + '&token=ASU588S123ADHI986JKAIA123SDGH')
            .then(res => {
                // console.log(res.data)
                sessionStorage.setItem('trackCurrent', JSON.stringify(res.data))
            }
            )
            .catch(err => console.error(err))

        history.push('/rastreio')
    }


    // if(dataSales.trackings.length === 0){
    //     return ' '
    // }

    const lengthTrack = (track) => {
        if (track.length === 0) {
            return <span className="error-message">Ainda não existe Código de rastreio para esse pedido, aguarde por favor. </span>
        } else {
            return (
                track.map((resp, index) => {
                    if (resp) {
                        return (
                            <div key={index}>
                                {/* { const testet = true} */}
                                {(!isLoading) && <button className="btn" onClick={() => onRequestApi(resp)}>Rastrear {resp}</button>}
                                {isLoading && <button disabled="disabled" className="btn load"><div className="spinner-load"></div></button>}
                            </div>
                        )
                    }else{
                        return ''
                    }
                })
            )
        }
    }
    return (
        <div className="single-order">
            <div className="column status-info">
                <h3>Pedido #{dataSales.id}</h3>
                <hr />
                <div className="row">
                    <div className="left-info">
                        <img src={dataSales.productImage} alt="" />
                    </div>
                    <div className="right-info">
                        <h4>{dataSales.productTitle}</h4>
                        <p>
                            <span>Realizado em: {formattedTime}</span><br />
                            <span>Status:  </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="column more-info">
                {/* {dataSales.trackings.map((tracking) => {
                    return (
                        {!isLoading && <button className="btn" onClick={() => onRequestApi(tracking)}>Rastrear Pacote</button>}
                        {isLoading  && <button disabled="disabled" className="btn load"><div className="spinner-load"></div></button>}
                        )
                    })} */}
                {lengthTrack(dataSales.trackings)}



                {/* <button disabled="disabled" className="btn load"><div className="spinner-load"></div></button> */}
                {/* <button disabled="disabled" className="btn load"><div className="spinner-load"></div>Rastrear Pacote</button> */}
            </div>
        </div >
    )
}

export default AreaOrder