import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Consulta from "./Components/Consulta/Consulta";
import Lista from "./Components/Lista/Lista";
import Rastreio from "./Components/Rastreio/Rastreio";

function Routes(){
    return (
    <BrowserRouter>
        <Switch>
            <Route path="/" exact   component = {Consulta} />
            <Route path="/lista" component = {Lista} />
            <Route path="/code/:urlCdTrack" component = {Rastreio} />
            <Route path="/rastreio/:urlCdTrack" component = {Rastreio} />
            <Route path="/rastreio/" component = {Rastreio} />
            <Route path='*' component={Consulta} />
        </Switch>
    </BrowserRouter>
    );
};

export default Routes;