import React, { Component } from 'react'
import {Redirect} from 'react-router-dom'
import './consulta.css';
import api from "../../api"



export default class Consulta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            form: {
                cpf: '',
                email: ''
            },
            redirect: false,
            isLoading: false,
            errorMessage: null
        }
        this.setRedirect = this.setRedirect.bind(this);
        this.doRedirect = this.doRedirect.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    doRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/lista' />
        }
    }

    validate(message) {
        return (
            <h1>Olá</h1>
        )
    }

    async componentDidMount(data) {

        if (data) {
            await api.post('/tracking/sales/', data)
                .then(res => {
                    this.setState({ data: res.data })

                    if (this.state.data.error === false) {
                        sessionStorage.setItem('data', JSON.stringify(res.data))
                        this.setRedirect()
                    } else {
                        // console.log(this.state.data.msg)
                        this.setState({errorMessage: this.state.data.msg})
                    }
                })
                .catch(err => {
                    this.setState({errorMessage: err})                    
                    console.error(err)

                })
                this.setState({ isLoading: false })
            }
    }

    submitHandler(e) {
        this.setState({ isLoading: true })

        e.preventDefault();

        // 	"cpf": "11087413710",
        // "email": "secco.raquel@gmail.com",

        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        // console.log(data)
        // console.log(this.state.form);
        this.componentDidMount(data)
    }
    render() {
        return (
            <div className="content-area">
                <h1>Consulta Localização</h1>
                <p>ATENÇÃO! Os Correios podem demorar até 48 horas para atualizar o código de rastreio, mesmo após informado.</p>
                <form onSubmit={this.submitHandler} >
                    {/* <input type="text" id="fcpf" name='cpf' value={this.state.form.cpf} onChange={(event) => { this.setState({ form {cpf: event.target.value} }) }} placeholder="CPF" required /> */}
                    {this.state.errorMessage && 
                    <div className="error-msg">
                        {this.state.errorMessage}
                    </div>
                    }
                    <input type="text" id="fcpf" name='cpf' value={this.state.form.cpf} onChange={(event) => { this.setState(() => ({ form: { cpf: event.target.value } })) }} placeholder="CPF"/>
                    <input type="email" id="femail" name="email" placeholder="Email" />
                    <input id="prodId" name="token" type="hidden" value="&ASUYGO*&H!H#U&(*YSDHE*)!@#H"></input>
                    {!this.state.isLoading && <button type="submit" id="btn-form" >Consultar</button>}
                    {this.state.isLoading && <button disabled="disabled" id="btn-form"> <div className="spinner-load"></div> Consultar</button>}

                </form>
                {/* <Link to="/rastreio">Ir para o Status</Link> */}
                {/* <Link to="/lista">Ir para a lista de produtos</Link> */}
                {this.doRedirect()}
            </div>
        );
    }
}